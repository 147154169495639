.contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    margin-top: 100px;
    justify-content: center;
}

.contact h1 {
    font-size: 35px;
    font-weight: 800;
    color: #ffffff;
    font-family: 'DM Sans', sans-serif;
    text-transform: uppercase;
}

.contact form {
    display: flex;
    flex-direction: column;
    width: 600px;
}

.contact form label {
    font-size: 18px;
    font-weight: 400;
    font-family: "Barlow", Sans-serif;
    line-height: 26px;
    color: #ffffff;
    margin-bottom: 15px;
    margin-top: 15px;
}

.contact form input {
    width: 100%;
    height: 45px;
    border: 1px solid #333333a9;
}

.contact form textarea {
    width: 100%;
    height: 145px;
    resize: none;
}

.contact form input[type=submit] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background: linear-gradient(45deg, #204b9a, #427ae4);
    margin-top: 25px;
    cursor: pointer;
    border-radius: 999px;

    color: #ffffff;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    font-weight: 400;
}

@media only screen and (max-width: 500px) {
    .contact h1 {
        font-size: 25px;
    }

    .contact form {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
}