.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: url('./assets/bg.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}