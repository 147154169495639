.item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #333333;
}

.item .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.item img {
    width: 400px;
    margin: 50px;
    border-radius: 5px;
    object-fit: cover !important;
    object-position: center;
}

#other {
    min-height: 600px;
}

.item .item-info {
    display: flex;
    flex-direction: column;
    margin: 50px;
    width: 100%;
}

.item .item-info h1 {
    font-size: 35px;
    font-weight: 800;
    color: #121315;
    font-family: 'DM Sans', sans-serif;
    text-transform: uppercase;
}

.item .item-info p {
    font-size: 18px;
    font-weight: 400;
    font-family: "Barlow", Sans-serif;
    line-height: 26px;
    width: 100%;
}

.item .item-info .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 60px;
    background: linear-gradient(45deg, #204b9a, #427ae4);
    margin-top: 25px;
    cursor: pointer;
    border-radius: 999px;
}

.item .item-info .btn p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    font-weight: 400;
}

@media only screen and (max-width: 1300px) {
    .item .card {
        flex-direction: column;
    }

    .item .item-info {
        align-items: center;
    }

    .item img {
        width: 500px;
    }
}

@media only screen and (max-width: 900px) {
    .item .item-info h1 {
        font-size: 25px;
    }

    .item img {
        margin-top: 0px;
        width: 100%;
    }
}