.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 700px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: cover;
    transition: 0.5s;
}

.header h1 {
    font-size: 60px;
    font-weight: 800;
    font-family: Poppins, sans-serif;
    color: #ffffff;
    padding: 15px;
}

.header p {
    font-size: 35px;
    font-weight: 800;
    color: #ffffff;
}

.header .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin-top: 80px;
    height: 60px;
    border-radius: 999px;
    background: linear-gradient(45deg, #204b9a, #427ae4);
    cursor: pointer;
    transition-duration: 0.5s;
}

.header .btn:hover {
    opacity: 0.8;
}

.header .btn p {
    font-size: 21px;
}

@media only screen and (max-width: 1300px) {
    .header h1 {
        font-size: 80px;
    }
    
    .header p {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1200px) {
    .header h1 {
        font-size: 80px;
    }
    
    .header p {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1000px) {
    .header h1 {
        font-size: 70px;
    }
    
    .header p {
        font-size: 25px;
    }
}

@media only screen and (max-width: 900px) {
    .header h1 {
        font-size: 60px;
    }
    
    .header p {
        font-size: 24px;
    }
}

@media only screen and (max-width: 800px) {
    .header h1 {
        font-size: 50px;
    }
    
    .header p {
        font-size: 24px;
    }
}

@media only screen and (max-width: 700px) {
    .header h1 {
        font-size: 40px;
    }
    
    .header p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 500px) {

    .header h1 {
        font-size: 30px;
    }
    
    .header p {
        font-size: 18px;
    }
}