.experts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    width: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    transition: 0.5s;
}

.experts img {
    width: 250px;
    margin-top: 50px;
    object-fit: cover;
    object-position: center;
}

.experts p {
    font-size: 35px;
    font-weight: 800;
    color: #ffffff;
}

.experts .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin-top: 80px;
    height: 60px;
    border-radius: 999px;
    background: linear-gradient(45deg, #b07d22, #f3e396);
    cursor: pointer;
    transition-duration: 0.5s;
}

.experts .experts_safe_area {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
}

.experts .experts_safe_area .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    margin-left: 25px;
    margin-right: 25px;
    background: #ffffff;
    min-height: 350px;
    margin-bottom: 60px;
    width: 100%;
    border: 1px solid #0000004d;
    cursor: pointer;
}

.experts .experts_safe_area .card img {
    width: 100px;
    height: 100px;
    object-position: center;
    object-fit: cover;
    margin: 15px;
}

.experts .experts_safe_area .card h1 {
    text-transform: uppercase;
    font-size: 18px;
}

.experts .experts_safe_area .card p {
    font-size: 18px;
    font-weight: 400;
    font-family: "Barlow", Sans-serif;
    color: #000000;
    width: 90%;
}

@media only screen and (max-width: 500px) {   
    .experts p {
        font-size: 25px;
    }
}

@media only screen and (max-width: 1300px) {
    .experts .experts_safe_area {
        flex-direction: column;
    }

    .experts .experts_safe_area .card {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}