.home {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
}

#callme {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  bottom: 25px;
  right: 25px;
  background: #2f9b41ff;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  z-index: 1;
}

#callme img {
  width: 40px;
  height: 40px;
  filter: invert(100%);
}

.contact-mobile {
    display: none;
    width: 100%;
    background: #ffffff;
}

@media only screen and (max-width: 500px) {
    .contact-mobile {
        display: flex;
    }
}