.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 100px;
    background: #ffffff;
}

.projects.start {
    scroll-behavior: smooth !important;
    transition: 1s;
}

.projects h1 {
    font-size: 45px;
    margin-left: 15px;
    font-weight: 800;
    color: #121315;
    font-family: 'DM Sans', sans-serif;
    font-size: 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 15px;
    z-index: 15;
}

.projects p {
    color: #666666;
    font-family: "Barlow", Sans-serif;
    font-size: 16px;
    margin-left: 15px;
    margin-top: 0px;
    font-weight: 400;
    line-height: 26px;
}

.projects .projects-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.projects .projects-main .projects_ {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.projects .projects-main .projects_ .projects_info {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.projects .projects-main .grid {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-template-columns: auto auto auto;
    text-align: center;
}

.projects .projects-main .grid img {
    width: 600px;
    height: 400px;
    margin: 5px;
    object-fit: cover;
    object-position: center;
    border: 1px solid #333333a9;
    padding: 15px;
    transition-duration: 0.2s;
    background: #ffffff;
}

.projects .projects-main .grid img:hover {
    border: 1px solid #204b9a;
}

.projects .projects-main .grid2 {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-template-columns: auto auto auto;
    text-align: center;
}

.projects .projects-main .grid2 img {
    width: 500px;
    height: 350px;
    margin: 5px;
    object-fit: cover;
    object-position: center;
    border: 1px solid #333333a9;
    border-radius: 15px;
    padding: 3px;
    transition-duration: 0.2s;
    background: #ffffff;
    transition-duration: 0.2s;
}

.projects .projects-main .grid2 img:hover {
    border: 1px solid #204b9a;
}

#separation {
    height: 40px;
}

#more {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}

#more p {
    width: 300px;
    height: 60px;
    color: #ffffff;
    background: #204b9a;
    border-radius: 999px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

#invert {
    transform: rotate(180deg);
}

#textHide {
    position: absolute;
    font-size: 70px;
    margin-top: 0px;
    padding-top: 0px;
    text-transform: uppercase;
    font-weight: 700;
    color: #56596927;
    letter-spacing: 15px;
    z-index: 5;
}

.welcomeVideo {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #000000d8;
    backdrop-filter: blur(3px);
    z-index: 25;
}

.welcomeVideo .video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
}

.welcomeVideo .video video {
    width: 100%;
    height: 400px;
    border: 1px solid #333333a9;
}

.welcomeVideo .video .buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}

.welcomeVideo .video .buttons img {
    position: absolute;
    background: #ffffff;
    margin-bottom: 15px;
    padding: 15px;
    width: 20px;
    height: 20px;
    object-fit: cover;
    object-position: center;
    border-radius: 999px;
    cursor: pointer;
}

#playbtn {
    position: fixed;
    width: 50px;
    height: 50px;
    padding: 15px;
    border-radius: 999px;
    filter: invert(100%);
    z-index: 5;
    pointer-events: none;
}

#preview {
    width: 100%;
    height: 500px;
    object-position: center;
    object-fit: contain;
}