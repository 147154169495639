.topbar {
    display: flex;
    flex-direction: row;
    margin: auto;
    position: fixed;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    left: 0px;
    right: 0px;
    top: 0px;
    background: linear-gradient(45deg, #16326886, #2b4e9186);
    box-shadow: 0px 0px 10px 0px #0d0d0d49;
    font-family: 'Poppins', sans-serif;
    backdrop-filter: blur(3px);
    border-bottom: 1px solid #ffffff36;
    z-index: 20;
}

.topbar img {
    width: 90px;
    margin-left: 35px;
}

.topbar .web {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.topbar .web p {
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 1px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 15px;
    cursor: pointer;
}

.topbar .mobile {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.topbar .mobile img {
    width: 60px;
    height: 60px;
    margin-right: 35px;
    object-position: center;
    object-fit: cover;
    filter: invert(100%);
    cursor: pointer;
}

.menu-mobile {
    display: flex;
    position: absolute;
    right: 0px;
    align-items: center;
    justify-content: center;
    left: 0px;
    bottom: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    background: #0c0c0c81;
    z-index: 20;
}

.menu-mobile .menu-items {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 15px;
    background: #0c0c0c81;
    backdrop-filter: blur(3px);
    border: 1px solid #ffffff36;
}

#budget-mobile {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    height: 50px;
    font-family: 'Poppins', sans-serif;
    background: #0c0c0cc5;
    border: 1px solid #ffffff36;
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .topbar .web {
        display: none;
    }

    .topbar .mobile {
        display: block;
    }
}