.options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #ffffff;
}

.options .options_safe_area {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
}

.options .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 60px;
    min-height: 400px;
    width: 100%;
    border: 1px solid #0000004d;
    cursor: pointer;
}

.options .card img {
    width: 100px;
    height: 100px;
    object-position: center;
    object-fit: cover;
    margin: 15px;
}

.options .card p {
    font-size: 18px;
    font-weight: 400;
    font-family: "Barlow", Sans-serif;
    width: 90%;
}

@media only screen and (max-width: 1300px) {
    .options .options_safe_area {
        flex-direction: column;
    }

    .options .card {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}