.comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    width: 100%;
}

.comments h1 {
    font-size: 35px;
    font-weight: 800;
    color: #121315;
    font-family: 'DM Sans', sans-serif;
    text-transform: uppercase;
}

.comments .comments_safe_area {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.comment .stars {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.comment .stars img {
    width: 25px;
    height: 25px;
    object-position: center;
    object-fit: contain;
}

.comments .comment {
    display: flex;
    flex-direction: column;
    margin: 15px;
    min-height: 270px;
    padding: 15px;
    border: 1px solid #333333a9;
    border-radius: 15px;
}

.comments .comment p {
    color: #121315;
    font-size: 18px;
    font-weight: 400;
    font-family: "Barlow", Sans-serif;
    line-height: 26px;
}

.comments .comment .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.comments .comment .info .info_ {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.comments .comment .info .info_ h1 {
    font-size: 20px;
    margin-left: 10px;
    text-transform: none;
    font-weight: 600;
}

.comments .comment .info img {
    width: 25px;
    height: 25px;
    object-position: center;
    object-fit: contain;
}

#profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background: #4240dd;
    border-radius: 999px;
    font-size: 20px;
    color: #ffffff;
    margin-left: 0px;
}

@media only screen and (max-width: 1500px) {
    .comments h1 {
        font-size: 25px;
    }

    .comments .comments_safe_area {
        display: grid;
        grid-template-columns: auto;
    }
}