.address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #ffffff;
}

.address .address_info {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.address .address_info h1 {
    color: #121315;
}

.address .address_info p {
    font-size: 18px;
    font-weight: 400;
    font-family: "Barlow", Sans-serif;
    color: #333333;
}

.address .address_info img {
    width: 300px;
    object-fit: cover;
    object-position: center;
}

.address iframe {
    width: 100%;
    margin: 15px;
    border: none;
}

@media only screen and (max-width: 1200px) {
    .address {
        flex-direction: column;
    }
}

@media only screen and (max-width: 500px) {
    .address .address_info h1 {
        font-size: 25px;
    }
}