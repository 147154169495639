.footer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background: linear-gradient(45deg, #204b9a, #427ae4);
}

.footer p {
    margin-left: 15px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    font-family: "Barlow", Sans-serif;
    line-height: 26px;
}

.footer .footer-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.footer .footer-section p {
    text-decoration: underline;
}

.footer .footer-section .footer-section-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;
}

.footer .socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.footer .socials .social-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.footer .socials .social-item p {
    font-size: 18px;
    font-weight: 400;
    font-family: "Barlow", Sans-serif;
    line-height: 26px;
}

.footer .socials img {
    width: 50px;
    height: 50px;
    margin: 10px;
    object-fit: cover;
    object-position: center;
    filter: invert(100%);
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .footer .footer-section {
        flex-direction: column;
    }
}

@media only screen and (max-width: 800px) {
    .footer .socials {
        flex-direction: column;
    }
}