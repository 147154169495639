.legal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 100px;
    background: #ffffff;
    min-height: 100vh;
}

.legal .legal_safe_area {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.legal p, h1 {
    color: #121315;
    font-weight: 400;
    font-family: "Barlow", Sans-serif;
}

.legal span {
    color: #121315;
    font-weight: bold;
}

#link {
    color: #204b9a;
    text-decoration: underline;
}